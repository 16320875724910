import React from 'react';

import { FormikValues, useFormikContext } from 'formik';

import Checkbox from '@components/Formik/Checkbox';
import ReferralAddressFields from '@presenters/web/components/Leads/Referral/ReferralAddressFields';
import AddressTypeSelect from '@presenters/web/pages/Profile/Edit/ContactInformation/AddressForm/AddressTypeSelect';

import { useTranslation } from '@external/react-i18next';

type Props = {
  countryId: string;
  primaryAddress: {
    countryId: string;
  };
};
const ReferralAddressForm: React.FC<Props> = props => {
  const { values } = useFormikContext<FormikValues>();

  const isAddressRequired = Boolean(values.primaryAddress.lineOne);
  const { t } = useTranslation();
  const { primaryAddress } = props;
  return (
    <div className="max-w-lg mt-8">
      <AddressTypeSelect
        name="type"
        label={t(
          'referral-club-meeting-preference.form.address-type-label',
          'Referral’s address type'
        )}
        required={isAddressRequired}
      />
      <ReferralAddressFields
        countryId={primaryAddress.countryId}
        mainLabel={t(
          'referral-club-meeting-preference.address-label',
          'Referral’s address'
        )}
        requiredFields={{ lineOne: false }}
        placeholder={{
          lineTwo: t('second-line-adress', 'Second line optional'),
          lineThree: t('third-line-adress', 'Third line optional'),
        }}
        name={{
          lineOne: 'primaryAddress.lineOne',
          lineTwo: 'primaryAddress.lineTwo',
          lineThree: 'primaryAddress.lineThree',
          countryId: 'primaryAddress.countryId',
          city: 'primaryAddress.city',
          stateId: 'primaryAddress.stateId',
          internationalProvince: 'primaryAddress.state',
          hasStates: 'primaryAddress.hasStates',
          postalCode: 'primaryAddress.postalCode',
        }}
        countrylabel={t(
          'referral-club-meeting-preference.country-label',
          'Referral’s country/region'
        )}
        citylabel={t(
          'referral-club-meeting-preference.city-label',
          'Referral’s city'
        )}
        stateLabel={t(
          'referral-club-meeting-preference.address.state-label',
          'Referral’s state/province/territory'
        )}
        postalcode={t(
          'referral-club-meeting-preference.postalcode-label',
          'Referral’s postal code'
        )}
      />
      <Checkbox
        name="club-meeting-preference-interest"
        label={t(
          'referral-club-meeting-preference.form.meeting-interest',
          'They are interested in an online meeting option, if available.'
        )}
      />
    </div>
  );
};

export default ReferralAddressForm;
