import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import ClubDetails from '@presenters/web/components/Leads/Forms/ClubDetails';
import ContactDetails from '@presenters/web/components/Leads/Forms/ContactDetails';
import MoreDetails from '@presenters/web/components/Leads/Forms/MoreDetails';
import { personalDetailsValidationSchemaForReferral } from '@presenters/web/pages/Leads/validationSchema';

import {
  getFormInformation,
  getFormInitialValue,
} from '@domain/leads/referral/formInfo';
import { FormFieldsProps, FormValues } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: (values: FormValues) => void;
  updatedValue: FormValues;
  handleShowStep: (flag: boolean, values: FormValues) => void;
}

const AboutMyReferral: React.FC<Props> = ({
  submitHandler,
  handleShowStep,
  updatedValue,
}) => {
  const { t } = useTranslation();

  const fieldProps: FormFieldsProps = getFormInformation(t, 'aboutMyReferral');
  const fieldInitialValue: FormValues = {
    ...getFormInitialValue('aboutMyReferral'),
    ...updatedValue,
  };

  const [isOnePageForm, setIsOnePageForm] = useState(
    updatedValue[fieldProps.preference.name] === 'own'
  );
  useEffect(() => {
    setIsOnePageForm(fieldInitialValue[fieldProps.preference.name] === 'own');
  }, [updatedValue]);

  return (
    <Formik
      initialValues={fieldInitialValue}
      onSubmit={submitHandler}
      validateOnBlur
      validationSchema={personalDetailsValidationSchemaForReferral(t)}
    >
      {({ isValid, submitCount }) => {
        return (
          <Form>
            <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10">
              <ClubDetails
                handleShowStep={handleShowStep}
                fieldProps={{
                  preference: { ...fieldProps.preference },
                  clubType: { ...fieldProps.clubType },
                }}
              />
              <h3>
                {t(
                  'join-form-referral.sub_head_about_my_referral',
                  'About my referral'
                )}
              </h3>
              <ContactDetails displayAgeCertification fieldProps={fieldProps} />
            </div>

            {isOnePageForm && (
              <MoreDetails
                fieldProps={{
                  profession: { ...fieldProps.profession },
                  additionalComments: { ...fieldProps.additionalComments },
                }}
              />
            )}
            {!isValid && !!submitCount && (
              <div className="mb-4">
                <FormElementErrorMessage id="global">
                  {t(
                    'contact-information.details_form.errors',
                    'The form contains errors. Please review and correct them first.'
                  )}
                </FormElementErrorMessage>
              </div>
            )}
            <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10 mb-6 desktop:mb-10">
              <Button type="submit" full>
                {(isOnePageForm &&
                  t('join-form-referral.button_submit_label', 'submit')) ||
                  t('join-form-referral.button_continue_label', 'continue')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default AboutMyReferral;
