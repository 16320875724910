import React from 'react';

import { useFormikContext } from 'formik';

import RadioField, { RadioOption } from '@components/Formik/RadioField';

import { FormFieldsProps, FormValues } from '@domain/leads/types';

export interface ClubDetailsProps {
  fieldProps: FormFieldsProps;
  handleShowStep: (flag: boolean, values: FormValues) => void;
}

const ClubDetails: React.FC<ClubDetailsProps> = ({
  fieldProps,
  handleShowStep,
}) => {
  const { values, setFieldValue } = useFormikContext<FormValues>();
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    setFieldValue(fieldProps.preference.name, target.value);

    handleShowStep(target.value === 'another', {
      ...values,
      [fieldProps.preference.name]: target.value,
    });
  };
  return (
    <>
      <div data-testid="button" onChange={handleChange}>
        <RadioField
          name={fieldProps.preference.name}
          label={fieldProps.preference.label}
          options={fieldProps.preference.options as RadioOption[]}
        />
      </div>
      {values[fieldProps.preference.name] === 'another' && (
        <RadioField
          name={fieldProps.clubType.name}
          label={fieldProps.clubType.label}
          options={fieldProps.clubType.options as RadioOption[]}
        />
      )}
    </>
  );
};

export default ClubDetails;
