import React from 'react';

import { useTranslation } from '@external/react-i18next';

const ReferralPrivacyPolicy: React.FC = () => {
  const { t } = useTranslation();
  return (
    <span className="text-sm">
      {t(
        'club-meeting-preference.referral.form.privacy-policy',
        'The personal data collected on this form is subject to <a target="_blank" href="https://my.rotary.org/en/privacy-policy"> Rotary’s privacy policy</a>. It will be shared with club and district officers and may be used for other membership-related activities. It will be shared with Rotary International in Great Britain and Ireland if you want to join a club in those locations.'
      )}
    </span>
  );
};

export default ReferralPrivacyPolicy;
