import React from 'react';

import { useFormikContext } from 'formik';

import TextField from '@components/Formik//TextField';
import CountrySelect from '@components/Formik/Select/CountrySelect';
import StateSelect from '@components/Formik/Select/StateSelect';

import { useTranslation } from '@external/react-i18next';

interface AddressFieldsProps {
  countryId?: string;
  mainLabel?: string;
  name: {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
    countryId?: string;
    city?: string;
    stateId?: string;
    state?: string;
    postalCode?: string;
    internationalProvince?: string;
    hasStates?: string;
  };
  placeholder?: {
    lineOne?: string;
    lineTwo?: string;
    lineThree?: string;
  };
  requiredFields?: {
    lineOne?: boolean;
    lineTwo?: boolean;
    lineThree?: boolean;
    country?: boolean;
    city?: boolean;
    state?: boolean;
    postalCode?: boolean;
  };
  countrylabel?: string;
  citylabel?: string;
  stateLabel?: string;
  postalcode?: string;
}

const ReferralAddressFields: React.FC<AddressFieldsProps> = ({
  countryId,
  mainLabel,
  countrylabel,
  citylabel,
  stateLabel,
  postalcode,
  name: {
    lineOne = 'address.lineOne',
    lineTwo = 'address.lineTwo',
    lineThree = 'address.lineThree',
    city = 'address.city',
    stateId = 'address.stateId',
    postalCode = 'address.postalCode',
    internationalProvince = 'address.internationalProvince',
    hasStates = 'address.hasStates',
  },
  name,
  placeholder,
  requiredFields,
}) => {
  const { t } = useTranslation();
  const { handleChange, setFieldValue } = useFormikContext();

  const {
    lineOne: requiredLineOne = true,
    lineTwo: requiredLineTwo = false,
    lineThree: requiredLineThree = false,
    country: requiredCountry = true,
    city: requiredCity = true,
    state: requiredState = true,
    postalCode: requiredPostal = false,
  } = requiredFields || {};

  return (
    <>
      <TextField
        name={lineOne}
        label={mainLabel || t('form.address.address-label', 'Address')}
        required={requiredLineOne}
        placeholder={placeholder?.lineOne}
      />
      <TextField
        name={lineTwo}
        label={t('form.address.address-label-line-two', 'Address line two')}
        labelHidden
        required={requiredLineTwo}
        placeholder={placeholder?.lineTwo}
      />
      <TextField
        name={lineThree}
        label={t('form.address.address-label-line-three', 'Address line three')}
        labelHidden
        required={requiredLineThree}
        placeholder={placeholder?.lineThree}
      />
      <CountrySelect
        name={name.countryId || 'address.countryId'}
        onChange={selectedOption => {
          setFieldValue(stateId, '');
          handleChange(name.countryId || 'address.countryId')(
            selectedOption.valueOf()
          );
        }}
        stateName={stateId}
        label={
          countrylabel || t('form.address.country-label', 'Country/Region')
        }
        required={requiredCountry}
        clearable
      />
      <TextField
        name={city}
        label={citylabel || t('form.address.city-label', 'City')}
        required={requiredCity}
      />
      {countryId && (
        <StateSelect
          countryId={countryId}
          name={stateId}
          label={
            stateLabel ||
            t('form.address.state-label', 'State/Province/Territory')
          }
          textStateName={internationalProvince}
          hasStatesName={hasStates}
          required={requiredState}
        />
      )}
      <TextField
        name={postalCode}
        label={postalcode || t('form.address.postalCode-label', 'Postal Code')}
        required={requiredPostal}
      />
    </>
  );
};

export default ReferralAddressFields;
