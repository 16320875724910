import React, { useState } from 'react';

import Checkbox from '@components/Formik/Checkbox';
import YearOfBirthSelect from '@components/Formik/Select/YearOfBirthSelect';
import TextField from '@components/Formik/TextField';

import GenderInput from './Fields/GenderInput';
import PhoneInput from './Fields/PhoneInput';

import { FormFieldsProps } from '@domain/leads/types';

import { getAge } from '@utils/datetime';

import { useMobileLayout } from '@hooks/useMobileLayout';

interface Props {
  fieldProps: FormFieldsProps;
  displayAgeCertification?: boolean;
}

const ContactDetails: React.FC<Props> = ({
  fieldProps,
  displayAgeCertification,
}) => {
  const { isMobileLayout } = useMobileLayout();
  const [isYouthFlag, setIsYouthFlag] = useState(displayAgeCertification);
  const handleBirthSelectChange = (birthYear: number | string) => {
    if (getAge(birthYear as number) <= 18) {
      setIsYouthFlag(true);
    } else {
      setIsYouthFlag(false);
    }
  };
  return (
    <>
      <TextField
        name={fieldProps.firstName.name}
        label={fieldProps.firstName.label}
        required={fieldProps.firstName.required}
      />
      <TextField
        name={fieldProps.lastName.name}
        label={fieldProps.lastName.label}
        required={fieldProps.lastName.required}
      />
      <TextField
        name={fieldProps.email.name}
        label={fieldProps.email.label}
        required={fieldProps.email.required}
        LeadsForms
      />
      <PhoneInput
        fieldProps={{
          phoneCode: { ...fieldProps.phoneCode },
          phoneNumber: { ...fieldProps.phoneNumber },
        }}
      />
      {fieldProps.yearOfBirth ? (
        <div className="tablet:flex desktop:flex desktop:flex-wrap">
          <div className="desktop:w-1/2 tablet:w-1/2 mobile:relative desktop:mr-4 tablet:mr-4">
            <YearOfBirthSelect
              {...fieldProps.yearOfBirth}
              onChange={handleBirthSelectChange}
              required={fieldProps.yearOfBirth.required}
            />
          </div>
          {isMobileLayout && isYouthFlag && (
            <Checkbox
              {...fieldProps.ageCertification}
              checkBoxClasses={{
                checkBoxLabel:
                  'm-0 normal-case font-bold text-xs block text-gray-600',
              }}
            />
          )}
          <div className="tablet:flex-1 desktop:w-1/2 tablet:w-1/2 mobile:relative desktop:ml-4 tablet:ml-4">
            <GenderInput
              fieldProps={{
                gender: { ...fieldProps.gender },
                genderDescription: { ...fieldProps.genderDescription },
              }}
            />
          </div>
        </div>
      ) : (
        <>
          <GenderInput
            fieldProps={{
              gender: { ...fieldProps.gender },
              genderDescription: { ...fieldProps.genderDescription },
            }}
          />
          {isMobileLayout && (
            <Checkbox
              {...fieldProps.ageCertification}
              checkBoxClasses={{
                checkBoxLabel: 'm-0 normal-case font-bold text-xs block',
              }}
            />
          )}
        </>
      )}
      {!isMobileLayout && isYouthFlag && (
        <Checkbox
          {...fieldProps.ageCertification}
          checkBoxClasses={{
            checkBoxLabel: 'm-0 normal-case font-bold text-xs block',
          }}
        />
      )}
    </>
  );
};

export default ContactDetails;
