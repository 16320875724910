import React, { useState } from 'react';

import { Button } from '@components/Button';
import { OneColumn } from '@components/Layouts/OneColumn';
import Header from '@presenters/web/components/Leads/Referral/Header';
import Steps from '@presenters/web/components/Leads/Steps/';

import AboutMyReferral from './AboutMyReferral';
import ClubMeetingPreferences from './ClubMeetingPreferences';

import { FormValues } from '@domain/leads/types';

import { localizedNavigate } from '@utils/localized-navigate';

import { useTranslation } from '@external/react-i18next';

const LandingPage: React.FC = () => {
  const { t } = useTranslation();
  enum formSteps {
    ABOUT_MY_REFERRAL,
    CLUB_MEETING_PREFERENCES,
    THANKYOU,
  }
  const [step, setStep] = useState<formSteps>(formSteps.ABOUT_MY_REFERRAL);

  const [showStep, setShowStep] = useState<boolean>(false);

  const handleBackArrow = (formStep: formSteps) => setStep(formStep);

  const [updatedValue, setUpdatedValue] = useState<FormValues>({});

  const handleShowStep = (flag: boolean, values: FormValues) => {
    setShowStep(flag);
    setUpdatedValue(values);
  };

  const handleHomePage = () => {
    localizedNavigate('/home');
  };

  const aboutMyReferralForm = (formStep: formSteps) => {
    return (
      <AboutMyReferral
        submitHandler={() => {
          setStep(formStep);
        }}
        handleShowStep={handleShowStep}
        updatedValue={{ ...updatedValue }}
      />
    );
  };

  const renderFormSteps = (formStep: formSteps) => {
    switch (formStep) {
      case formSteps.ABOUT_MY_REFERRAL:
        return (
          <div>
            {(showStep && (
              <Steps step={1} total={2}>
                {aboutMyReferralForm(formSteps.CLUB_MEETING_PREFERENCES)}
              </Steps>
            )) ||
              aboutMyReferralForm(formSteps.THANKYOU)}
          </div>
        );
      case formSteps.CLUB_MEETING_PREFERENCES:
        return (
          <Steps
            step={2}
            total={2}
            backHandler={() => handleBackArrow(formSteps.ABOUT_MY_REFERRAL)}
          >
            <ClubMeetingPreferences
              submitHandler={() => {
                setStep(formSteps.THANKYOU);
              }}
            />
          </Steps>
        );
      case formSteps.THANKYOU:
        return (
          <div className="max-w-sm mb-20 mt-8">
            <Button type="submit" full clickHandler={handleHomePage}>
              {t(
                'join-form-referral-thank-you.page.homepage-button',
                'Go to Homepage'
              )}
            </Button>
          </div>
        );
      default:
        return null;
    }
  };

  const hideInfo: boolean = step === formSteps.CLUB_MEETING_PREFERENCES;

  const title =
    step === formSteps.THANKYOU
      ? t('join-form-referral.title_thankyou', 'Thank you!')
      : t('join-form-referral.title_make_referral', 'Make a referral');

  const subtitle = (step: number) => {
    let subtitleInfo = '';
    if (step === formSteps.THANKYOU) {
      if (updatedValue.club_preference !== 'another') {
        subtitleInfo = t(
          'join-form-referral.title_info_thankyou',
          'We’ll send this information to your club.'
        );
      } else {
        subtitleInfo = t(
          'join-form-referral.another.title_info_thankyou',
          'We’ll send this to clubs in their area so the clubs can contact them directly.'
        );
      }
    } else {
      subtitleInfo = t(
        'join-form-referral.title_info_make_referral',
        'Tell us about your friend, family member, or colleague. We’ll forward their name and contact information to a district that can match them with a club.'
      );
    }
    return subtitleInfo;
  };

  const info = subtitle(step);

  return (
    <OneColumn>
      <Header showInfo={!hideInfo} title={title} info={info} />
      {renderFormSteps(step)}
    </OneColumn>
  );
};

export default LandingPage;
