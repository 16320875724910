import React from 'react';

import Select, { SelectOption } from '@components/Formik/Select';
import TextArea from '@components/Formik/TextArea';

import ReferralPrivacyPolicy from '../PrivacyPolicy/ReferralPrivacyPolicy';

import { FormFieldsProps } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

interface MoreDetailsProps {
  fieldProps: FormFieldsProps;
}

const MoreDetails: React.FC<MoreDetailsProps> = ({ fieldProps }) => {
  const { t } = useTranslation();
  return (
    <>
      <hr className="border-t-0 border-b w-full mt-6 desktop:mt-8 mb-8 border-gray-300" />
      <div className="desktop:flex-row max-w-lg">
        <p>
          {t(
            'join-form-referral.more_detail_hint',
            'Please tell us a little bit more about your referral.'
          )}
        </p>
        <Select
          {...fieldProps.profession}
          options={fieldProps.profession.options as SelectOption[]}
          clearable
          searchable
        />
        <TextArea
          {...fieldProps.additionalComments}
          characters={250}
          areaclasses="h-40"
        />
        <ReferralPrivacyPolicy />
      </div>
    </>
  );
};

export default MoreDetails;
