import React from 'react';

import { Form, Formik } from 'formik';

import { Button } from '@components/Button';
import { FormElementErrorMessage } from '@components/Forms/FormElement';
import MoreDetails from '@presenters/web/components/Leads/Forms/MoreDetails';
import { addressInformationForReferralValidationSchema } from '@presenters/web/pages/Leads/validationSchema';

import ReferralAddressForm from './ReferralAddressForm';

import { getFormInformation } from '@domain/leads/referral/formInfo';
import { AddressProps, FormFieldsProps } from '@domain/leads/types';

import { useTranslation } from '@external/react-i18next';

interface Props {
  submitHandler: () => void;
}

const ClubMeetingPreferences: React.FC<Props> = ({ submitHandler }) => {
  const { t } = useTranslation();
  const fieldProps: FormFieldsProps = getFormInformation(t, 'aboutMyReferral');
  const { profession, additionalComments } = fieldProps;

  const formikProps = {
    type: '',
    primaryAddress: {
      lineOne: '',
      lineTwo: '',
      lineThree: '',
      city: '',
      postalCode: '',
      state: '',
      stateId: '',
      countryId: '',
    },
  };

  return (
    <Formik
      initialValues={formikProps}
      onSubmit={() => {
        submitHandler();
      }}
      validationSchema={addressInformationForReferralValidationSchema(t)}
    >
      {({ values, isValid, submitCount }) => {
        const { countryId } = values.primaryAddress as AddressProps;

        return (
          <Form>
            <h3 className="mt-6 mb-2 text-black">
              {t(
                'join-form-referral.sub_head_club_meeting_preferences',
                'Club meeting preferences'
              )}
            </h3>
            <p className="desktop:mb-0">
              {t(
                'join-form-referral.club_meeting_preferences_info',
                'Enter the address of the person you’re referring, and we’ll do our best to match them with a club near them and a meeting time that fits their schedule.'
              )}
            </p>
            <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-0">
              <ReferralAddressForm
                countryId={countryId as string}
                {...values}
              />
            </div>
            <MoreDetails
              fieldProps={{
                profession,
                additionalComments,
              }}
            />
            {!isValid && !!submitCount && (
              <div className="mb-4">
                <FormElementErrorMessage id="global">
                  {t(
                    'club_metting_preference.details_form.errors',
                    'The form contains errors. Please review and correct them first.'
                  )}
                </FormElementErrorMessage>
              </div>
            )}
            <div className="desktop:flex-row max-w-lg mt-6 desktop:mt-10 mb-6 desktop:mb-10">
              <Button type="submit" full>
                {t('join-form-referral.button_submit_label', 'submit')}
              </Button>
              <Button full text type="button" className="mt-6">
                {t('rejoin-form-edit.cancel-label', 'Cancel')}
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ClubMeetingPreferences;
