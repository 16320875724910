import React from 'react';

import NotFound from '@components/Routing/NotFound';
import { LandingPage } from '@presenters/web/pages/Leads/Referral';

import { FEATURE_LEADS_JOIN_FORMS, isEnabled } from '@utils/features';

const ReferralPage: React.FC = () => {
  const isLeadJoinFormEnabled = isEnabled(FEATURE_LEADS_JOIN_FORMS);
  if (!isLeadJoinFormEnabled) {
    return <NotFound />;
  }
  return <LandingPage />;
};

export default ReferralPage;
