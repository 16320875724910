import React from 'react';

import classNames from 'classnames';

import Icon from '@components/Icon';
import Link from '@components/Link';

import { useTranslation } from '@external/react-i18next';

/**
 * Wrapper that adds "Step X of Y" and back links to the tops of pages.
 */
export type StepsProps = {
  step: number;
  total: number;
  to?: string;
  showModal?: () => void;
  backHandler?: () => void;
  backBtnClassName?: string;
  navClassName?: string;
  customBackBtnTitle?: string;
  isArrowLarge?: boolean;
};

const Steps: React.FC<StepsProps> = ({
  step = 1,
  total,
  children,
  to,
  showModal,
  backHandler,
  backBtnClassName = '',
  navClassName = '',
  customBackBtnTitle = '',
  isArrowLarge = false,
}) => {
  const { t } = useTranslation();

  const leftArrow = isArrowLarge ? (
    <Icon name="arrows/left" className="mr-2" size="12" />
  ) : (
    <span className="mr-1">&#8249;</span>
  );

  return (
    <>
      <p
        className={
          navClassName || 'text-small font-bold mt-6 desktop:mt-8 mb-0'
        }
      >
        {(backHandler || to) && (
          <>
            {backHandler && (
              <button
                type="button"
                onClick={backHandler}
                className={classNames('text-small font-bold link-styles', {
                  [backBtnClassName]: !!backBtnClassName,
                })}
                data-testid="page-step-back-button"
              >
                &#8249; {t('page-steps.back-button', 'Back')}
              </button>
            )}
            {to && (
              <Link className={backBtnClassName} to={to} showModal={showModal}>
                {leftArrow}
                {customBackBtnTitle || t('page-steps.back-button', 'Back')}
              </Link>
            )}
            <span className="inline-block mx-1">&#124;</span>
          </>
        )}
        {total > 1 && (
          <>
            {t(
              'pages-steps.step-X-of-Y-total-steps',
              'Step {{current}} of {{total}}',
              {
                current: step,
                total,
              }
            )}
          </>
        )}
      </p>
      {children}
    </>
  );
};

export default Steps;
