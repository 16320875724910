import React from 'react';
import Select, { SelectOptions, SelectProps } from '../Select';

// last 100 years with an offset of 18 are selectable
const options = (acceptedAge: number = 18, yearsRange: number = 100) => {
  const val: SelectOptions = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear - yearsRange; i <= currentYear - acceptedAge; i++) {
    val.unshift({ label: String(i), value: String(i) });
  }
  return val;
};

type YearOfBirthSelectProps = Omit<SelectProps, 'options'> & {
  acceptedAge?: number;
  yearsRange?: number;
};

const YearOfBirthSelect: React.FC<YearOfBirthSelectProps> = props => (
  <Select
    searchable
    options={options(props.acceptedAge, props.yearsRange)}
    {...props}
  />
);

export default YearOfBirthSelect;
